<template>
  <div class="control">
  </div>
</template>

<script>

export default {
  name: "Control",
  components: {
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
body, html{
  width: 100%;
  height: 100%;
}
</style>